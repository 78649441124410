<!-- Informes de Expedientes -->

<template>
  <div class="exp_Report_F" v-if="schema && $store.state[stName].recordsDetalle.length">
    <div class="conflex" style="justify-content:center">
      <v-sheet elevation="4">

        <!-- Cabecera -->
        <div v-if="Entorno.header.header">       
          <base_Header 
            :Entorno="Entorno.header"
            @onEvent="event_Header">          
          </base_Header>
        </div>
        
        <!-- Botonera -->
        <div class="pt-2 pl-2">
          <btraextra
            :btra_permisos="schema.btra"
            :btra_particular="Entorno.btra"
            btra_modulo="top"
            @onEvent="event_btra_Top">              
          </btraextra>
        </div>

        <!-- Contenido -->
        <div class="contenedor conflex" style="width:950px">
          <div class="columna" style="width:32%">
            <!-- Menú -->  
            <div class="cab">Tipos de Informes</div>
            <v-sheet v-bind="$cfg.styles.marco">        
              <v-treeview
                :items="$store.state[stName].recordsDetalle[0]"
                item-key="d"
                item-text="n"
                item-children="c"
                activatable
                dense
                open-on-click
                return-object
                @update:active="change_inf">            
              </v-treeview>
            </v-sheet>
          </div>
          
          <div class="columna" style="width:66%;margin-left:10px">            
            <div class="cab">Condiciones</div>
            <v-sheet v-bind="$cfg.styles.marco"> 
              <div style="conflex">
                <v-select
                  v-bind="$select"
                  v-model="schema.ctrls.ord.value"
                  :label="schema.ctrls.ord.label"
                  :items="ord"
                  item-value="d"
                  item-text="n">              
                </v-select>
              </div>

              <div style="conflex">
                <v-select
                  v-bind="$select"
                  v-model="schema.ctrls.tiposer.value"
                  :label="schema.ctrls.tiposer.label"
                  :items="$store.state[stName].recordsDetalle[2]"
                  item-value="d"
                  item-text="n">            
                </v-select>

                <v-select
                  v-bind="$select"
                  v-model="schema.ctrls.estado.value"
                  :label="schema.ctrls.estado.label"
                  :items="$store.state[stName].recordsDetalle[3]"
                  item-value="d"
                  item-text="n">            
                </v-select>
              </div>

              <div style="display:flex">
                <v-select                
                  v-bind="$select"
                  v-model="schema.ctrls.situacion.value"
                  :label="schema.ctrls.situacion.label"
                  :items="$store.state[stName].recordsDetalle[4]"
                  item-value="d"
                  item-text="n">            
                </v-select>

                <v-select                  
                  v-bind="$select"
                  v-model="schema.ctrls.cierres.value"
                  :label="schema.ctrls.cierres.label"
                  :items="$store.state[stName].recordsDetalle[5]"
                  item-value="d"
                  item-text="n">                
                </v-select>
              </div>

              <div style="display:flex">
                <v-select   
                  style="flex: 0 0 50%"               
                  v-bind="$select"                
                  label="Garantías"
                  :items="$store.state[stName].recordsDetalle[6]"
                  item-value="d"
                  item-text="n"
                  multiple>  
                    <template v-slot:selection="{ index }">              
                      <span  
                        v-if="index=== 0"              
                        class="grey--text caption">                
                          (Incluye {{ schema.ctrls.conGarantias.value.length }} garantías),
                          (Excluye {{ schema.ctrls.sinGarantias.value.length }} garantías)
                      </span>
                    </template>

                    <template v-slot:item="{ item }">              
                      <v-checkbox
                        v-bind="$checkbox"
                        v-model="schema.ctrls.conGarantias.value"
                        :value="item.d"
                        color="green">
                      </v-checkbox>

                      <v-checkbox                      
                        v-bind="$checkbox"
                        v-model="schema.ctrls.sinGarantias.value"
                        :value="item.d">
                      </v-checkbox>

                      <span>{{ item.n }}</span>
                    </template>           
                </v-select>

                <v-select   
                  style="flex: 0 0 50%"               
                  v-bind="$select"                
                  label="Servicios"
                  :items="$store.state[stName].recordsDetalle[7]"
                  item-value="d"
                  item-text="n"
                  multiple>  
                    <template v-slot:selection="{ index }">              
                      <span  
                        v-if="index=== 0"              
                        class="grey--text caption">                
                          (Incluye {{ schema.ctrls.conServicios.value.length }} servicios),
                          (Excluye {{ schema.ctrls.sinServicios.value.length }} servicios)
                      </span>
                    </template>

                    <template v-slot:item="{ item }">              
                      <v-checkbox                     
                        v-bind="$checkbox"
                        v-model="schema.ctrls.conServicios.value"
                        :value="item.d"
                        color="green">
                      </v-checkbox>

                      <v-checkbox                      
                        v-bind="$checkbox"
                        v-model="schema.ctrls.sinServicios.value"
                        :value="item.d">
                      </v-checkbox>

                      <span>{{ item.n }}</span>
                    </template>           
                </v-select>
              </div>

              <div style="display:flex">
                <ctrl_multifilterFechas
                  style="flex: 0 0 100%"
                  tipo="periodo"
                  :items="get_fechas"
                  :schema="schema"
                  @onEvent="update_multifilterFechas">              
                </ctrl_multifilterFechas>
              </div>

              <div style="display:flex">
                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.control.value"
                  :label="schema.ctrls.control.label">              
                </v-text-field>

                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.poliza.value"
                  :label="schema.ctrls.poliza.label">                
                </v-text-field>
                
                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.ramo.value"
                  :label="schema.ctrls.ramo.label">                
                </v-text-field>

                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.modalidad.value"
                  :label="schema.ctrls.modalidad.label">                
                </v-text-field>
              </div>
              
              <div style="display:flex">
                  <v-select   
                    style="flex: 0 0 30%"               
                    v-bind="$select"
                    v-model="schema.ctrls.lugar.value"
                    :label="schema.ctrls.lugar.label"
                    :items="$store.state[stName].recordsDetalle[10]"
                    item-value="d"
                    item-text="n">                
                  </v-select>
                
                  <ctrlfinder style="flex: 0 0 70%" finderName="atlas_F" :schema="schema.ctrls.lugar_loc"></ctrlfinder>
              </div>

              <div style="display:flex">
                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.region.value"
                  :label="schema.ctrls.region.label">            
                </v-text-field>              
              </div>

              <div style="display:flex">
                <!-- BOTONES -->
              </div>

              <div style="display:flex">              
                <compMultiple
                  style="flex: 0 0 100%"
                  :ctrl="schema.ctrls.callemp"
                  :schema="schema">               
                </compMultiple>
              </div>

              <div style="display:flex">
                <ctrlfinder
                  style="flex: 0 0 100%"
                  finderName="funes_F"
                  :schema="schema.ctrls.fune">
                </ctrlfinder>
              </div>

              <div style="display:flex">
                <v-select
                  style="flex: 0 0 30%"
                  v-bind="$select"
                  v-model="schema.ctrls.grupo.value"
                  :label="schema.ctrls.grupo.label"            
                  :items="$store.state[stName].recordsDetalle[12]" 
                  item-value="d"
                  item-text="n"          
                  multiple>                
                
                    <template v-slot:selection="{ index }">              
                      <span  
                        v-if="index=== 0"              
                        class="grey--text caption">                
                          ({{ schema.ctrls.grupo.value.length }} grupo/s seleccionado/s)
                      </span>
                    </template>
                </v-select>

                <ctrlfinder
                  style="flex: 0 0 70%"
                  finderName="funec_F"
                  :schema="schema.ctrls.central">
                </ctrlfinder>
              </div>

              <div style="display:flex">
                <v-select   
                  style="flex: 0 0 30%"               
                  v-bind="$select"
                  v-model="schema.ctrls.tipo_age.value"
                  :label="schema.ctrls.tipo_age.label"
                  :items="$store.state[stName].recordsDetalle[9]"
                  item-value="d"
                  item-text="n">                
                </v-select>

                <ctrlfinder        
                  style="flex: 0 0 70%"       
                  finderName="agen_F"
                  :schema="schema.ctrls.agencia">
                </ctrlfinder>
              </div>

              <div style="display:flex">
                <!-- <ctrlfinder        
                  style="flex: 0 0 50%"       
                  finderName="jefeszona_F"
                  :schema="schema.ctrls.zona">
                </ctrlfinder> -->
                <v-select   
                  style="flex: 0 0 30%"               
                  v-bind="$select"
                  v-model="schema.ctrls.tipo_zon.value"
                  :label="schema.ctrls.tipo_zon.label"
                  :items="$store.state[stName].recordsDetalle[8]"
                  item-value="d"
                  item-text="n">                
                </v-select>
                <v-select  
                  style="flex: 0 0 70%"                   
                  v-bind="$select"
                  v-model="schema.ctrls.zona.value"
                  :label="schema.ctrls.zona.label"
                  :items="itemsZonas"
                  item-value="id"
                  item-text="name"
                  :disabled="zona >0">
                </v-select>
              </div>
              
              <div style="display:flex">
                <ctrlfinder
                  style="flex: 0 0 50%"
                  finderName="apd_F"
                  :schema="schema.ctrls.apd">
                </ctrlfinder>              
                <v-select   
                  style="flex: 0 0 50%"               
                  v-bind="$select"
                  v-model="schema.ctrls.ctramit.value"
                  :label="schema.ctrls.ctramit.label"
                  :items="$store.state.datos_iniciales.ct"
                  item-value="id"
                  item-text="label">                
                </v-select>
              </div>
            </v-sheet>
          </div> 
          
        </div>
      </v-sheet>
    </div>
  </div>
</template>



<script>

  import { mixinFinder } from "@/mixins/mixinFinder.js";
  import { mixinReport } from "@/mixins/mixinReport.js";
  import plugs from "@/common/general_plugs";
  const base_Header = () => plugs.groute("base_Header.vue", "base");
  const btraextra = () => plugs.groute("btraextra.vue", "comp");
  const ctrlfinder = () => plugs.groute("ctrlfinder.vue", "comp");
  const ctrl_multifilterFechas = () => plugs.groute("ctrl_multifilterFechas.vue", "comp");
  const compMultiple = () => plugs.groute("compMultiple.vue", "comp");

  export default {
    mixins: [mixinFinder, mixinReport],
    components: { base_Header, btraextra, ctrlfinder, ctrl_multifilterFechas, compMultiple },
    props: {
      componenteTipo: { type:String, default:'F' },
      Entorno: { type: Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.F)); }}
    },

    data() {
      return {
        schema:null,
        api:'exp_Report_F',
        stName:'stFexp_Report',

        headers: {},
        ord: [],               
        empName:'',        
        selected_multifilterFechas:null,
        itemsZonas:[],       
      };
    },


    computed:{     
      get_schema_callemp() {
        console.log("dev ********************** exp_M_llamada get_schema_callemp");

        let s_callemp = this.schema.ctrls.callemp;
        let tipoEmp = this.$store.state[s_callemp.comp.source];
        let tipo = Number(this.schema.ctrls[s_callemp.comp.f2]["value"]) - 1;
        
        // obtengo el nombre del ctrlfinder a mostrar
        s_callemp.comp.finder = tipoEmp[tipo < 0 ? 0 : tipo].finder;

        this.setCallemp(s_callemp);        
        return s_callemp;
      },

      get_fechas() {
        if (!this.$store.state[this.stName].recordsDetalle[13]) return [];
        return this.$store.state[this.stName].recordsDetalle[13];
      }

    },


    methods: {
      ini_data() {
        console.log("DEV " + this.api + " ********************** (ini_data)");

        // guardo Schema del store en variable local
        this.schema = this.$store.state[this.stName].schema;
      
        // configuro Entorno          
        this.Entorno.header.titulo = "Informe Expedientes";
        this.Entorno.grid.autoload= true;
        this.Entorno.btra= JSON.parse(JSON.stringify(this.$cfg.btra_tipo["F"]));        
        this.Entorno.btra.footer.extra= {};
        this.Entorno.btra.footer.extra= [
          { accion: "ver_Pdf", btn: "btn_pdf", show: 1 },
          { accion: "ver_Excel", btn: "btn_excel", show: 1 }          
        ];

        // items adicionales
        this.itemsZonas= this.$store.state.datos_iniciales.zonas.slice();        
        this.itemsZonas.unshift({id:'0',name:'( Selecciona )'});
      },


      //
      ini_schemaCtrls() {        
        this.schema.ctrls.zona.value= this.zona;
      }, 


      //
      update_multifilterFechas(evt) {
        if (evt.accion== 'change') this.selected_multifilterFechas= evt.item;
      },


      // muestro informe en pdf
      ver_Pdf() {       
        this.show_report("pdf");       
      },


      // muestro informe en excel
      ver_Excel() {        
        this.show_report("xls");
      },


      // muestro informe
      async show_report(tipo) {
        // compruebo si hay seleccionado un Informe
        if (this.schema.ctrls.inf.value== "") {
          this.$root.$alert.open('Debes seleccionar un informe', 'error');
          return;
        }

        // monto los argumentos de la API
        let fn_args= {};
        fn_args['accion']= "informe";
        fn_args["ctrls"]= this.ctrl2linea(this.schema.ctrls);
        fn_args['report']= "exped";
        fn_args['tip']= tipo;

        // fecha multifilterFechas por omisión
        // si no hay período de fecha seleccionado, pongo el día actual
        if (!this.selected_multifilterFechas) return;
        if (this.schema.ctrls[this.selected_multifilterFechas.ctrl].value== "" ) {         
          fn_args["ctrls"][this.schema.ctrls[this.selected_multifilterFechas.ctrl].f]= this.actualDate(true) + '|' + this.actualDate(true);          
        }

        this.report(fn_args);
      }


    }
  };
</script>
